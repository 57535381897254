import { useState } from "react";
import { ethers, BigNumber } from "ethers";
import { Box, Button, Flex, Input, Text } from "@chakra-ui/react"
import { Slider, SliderTrack, SliderFilledTrack, SliderThumb } from '@chakra-ui/react'

import Contract from '../Contract.json'
import env from '../env';

import updateContractInfo from '../Metamask/updateContractInfo';

import { getParsedEthersError } from "@enzoferey/ethers-error-parser";

const ProjectMint=({ dataEth, setDataEth, message, setMessage }) => {
    const [ mintAmount, setMintAmount ]=useState(1);
    const [ freeMintAmount, setFreeMintAmount ]=useState(1);

    const onMintedSuccess = (_mintedSuccess, freeMint) => {
        
        let mensaje={
            title: (freeMint? "Free " : "") + "Mint Succesful",
            description: "Please allow a few minutes to see your new NFTs in your collection",
            status: "success",
        }
        if (!_mintedSuccess) {
            mensaje={
                title: "Error " + (freeMint? "Free " : "") +  + "Minting",
                description: "Please check your wallet transaction on etherscan",
                status: "error",
            }
        }
        setMessage(mensaje)
    }

    async function handleFreeMintClick() {
        handleMint(true);
    }

    async function handleMintClick() {
        handleMint(false);
    }

    async function handleMint(freeMint) {
        if (!dataEth?.accountEth) return

        try {
            const mintPrice = dataEth.contractInfo.mintPrice;
            const freeMintPrice = dataEth.contractInfo.freeMintPrice;

            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(
                env.mintContract,
                Contract.abi,
                signer
            );

        
            let response;
        
            if (freeMint) {
                response = await contract.freeMint(BigNumber.from(freeMintAmount), {
                    value: ethers.utils.parseEther((freeMintPrice*freeMintAmount).toString()),
                });
            } else {
                response = await contract.mint(BigNumber.from(mintAmount), {
                    value: ethers.utils.parseEther((mintPrice*mintAmount).toString()),
                });
            }
        
            const txHash=response.hash;
            const data = {
                    txID: txHash, waitingForTx: true, mintedSuccess: false,
            };

            setDataEth((prevState) => ({
                ...prevState,
                tx: data
            }));
            
            const txReceipt=await response.wait();
            const mintedSuccess=txReceipt && txReceipt.blockNumber && txReceipt.blockNumber!==undefined;
        
            const data2 = { 
                txID: txHash, 
                txReceipt: txReceipt, 
                waitingForTx: !mintedSuccess, 
                mintedSuccess: mintedSuccess };
            
            const contractInfo=await updateContractInfo(contract, dataEth.accountEth.account);
            setDataEth((prevState) => ({
                ...prevState,
                tx: data2,
                contractInfo: contractInfo
            }));
            
            onMintedSuccess(mintedSuccess, freeMint);
        
        } catch (error) {
            // @ts-ignore
            
            console.error(error);
            let msgDecrypted=getParsedEthersError(error);
            if (msgDecrypted.errorCode==="REJECTED_TRANSACTION") {
                setMessage({title: "Error", description: "User rejected transaction", status: "error"})
            } else {
                console.log(msgDecrypted);
                setMessage({title: "Error", description: (msgDecrypted.context), status: "error"})
            }

            return;
        }

    } 

    const handleDecrement = () => {
        if (mintAmount<=1) return;
        setMintAmount(mintAmount-1);
    }

    const handleIncrement = () => {
        if (mintAmount>=dataEth.contractInfo.maxMintsPerTX) return;
        setMintAmount(mintAmount+1);
    }

    const handleFreeDecrement = () => {
        if (freeMintAmount<=1) return;
        setFreeMintAmount(freeMintAmount-1);
    }

    const handleFreeIncrement = () => {
        if (freeMintAmount>=dataEth.contractInfo.maxFreeMintsPerTX) return;
        setFreeMintAmount(freeMintAmount+1);
    }

    const availableFreeMint = () => {
        return (
            <>
                <Flex align="center" justify="center">
                    <Input
                        readOnly
                        fontFamily="inherit"
                        width="250px"
                        height="40px"
                        textAlign="center"
                        paddingLeft="0px"
                        marginTop="10px"
                        color="black"
                        backgroundColor="transparent"
                        borderColor="transparent"
                        value={"Free Mint  " +freeMintAmount.toString()+" "+dataEth.contractInfo.tokenSymbol} />
                </Flex>
                <div style={{'marginTop': '25px'}} align="center" justify="center" >
                    <Flex align="center" justify="center">
                        <Box paddingRight="15" color="black.800" onClick={(value)=>handleFreeDecrement()} >
                            <Button
                                backgroundColor='#0c8a1c'
                                color='#fafafa'
                                aria-label={'Minus '+dataEth.contractInfo.tokenSymbol}
                            >-</Button>
                        </Box>
                        <Slider 
                            value={freeMintAmount} min={1} max={dataEth.contractInfo.maxFreeMintsPerTX} step={1} onChange={(value)=> {setFreeMintAmount(value)}}
                            width="400px"
                            >
                            <SliderTrack bg={'red.300'}>
                                <Box color='black.800'/>
                                <SliderFilledTrack bg={'black.700'} />
                            </SliderTrack>
                            <SliderThumb backgroundColor="#0c8a1c" boxSize={4} />
                        </Slider>
                        <Box align="center" paddingLeft="15" color="black" onClick={(value)=>handleFreeIncrement()} >
                            <Button
                                backgroundColor='#0c8a1c'
                                color='#fafafa'
                                aria-label={'Add '+dataEth.contractInfo.tokenSymbol}
                            >+</Button>
                        </Box>
                    </Flex>

                    <Button
                        style={{ display: (dataEth.contractInfo.walletFreeMintsEnabled && !dataEth.contractInfo.freeMintEnded) ? 'blocked' : 'none'}}
                        backgroundColor="#0c8a1c"
                        borderRadius="1px"
                        boxShadow="0px 2px 2px 1px #0F0F0F"
                        color="white"
                        cursor="pointer"
                        fontFamily="inherit"
                        padding="15px"
                        marginTop="10px"
                        onClick={handleFreeMintClick}
                    >
                        {(dataEth?.contractInfo?.freeMintPrice ? Number(dataEth.contractInfo.freeMintPrice * 1) : 0) === 0 ? 'Free Mint': 'Almost Free Mint'}
                    </Button>

                    <Text marginTop="10px" fontSize="12px" textShadow="0 2px #DADADE">{dataEth?.contractInfo?.freeMintPrice ? Number(dataEth.contractInfo.freeMintPrice * 1).toFixed(5) : ""} Ξ</Text>
                    <Text fontSize="12px" textShadow="0 2px #DADADE">{dataEth?.contractInfo?.maxFreeMintsPerTX ? dataEth.contractInfo.freeMintCount + " / " + dataEth.contractInfo.freeSupply : ""}</Text>

                </div>
            </>
        )
    }


    const availableMint = () => {
        if (dataEth.contractInfo.walletFreeMintsEnabled && !dataEth.contractInfo.freeMintEnded) {
            return availableFreeMint();
        } else { 
            return (
            <>
                <Flex align="center" justify="center">
                    <Input
                        readOnly
                        fontFamily="inherit"
                        width="150px"
                        height="40px"
                        textAlign="center"
                        paddingLeft="0px"
                        marginTop="10px"
                        color="black"
                        backgroundColor="transparent"
                        borderColor="transparent"
                        value={mintAmount.toString()+" "+dataEth.contractInfo.tokenSymbol} />
                </Flex>
                <Flex align="center" justify="center">
                    <Box paddingRight="15" color="black.800" onClick={(value)=>handleDecrement()} >
                        <Button
                            backgroundColor='black'
                            color='#fafafa'
                            aria-label={'Minus '+dataEth.contractInfo.tokenSymbol}
                        >-</Button>
                    </Box>
                    <Slider 
                        value={mintAmount} min={1} max={dataEth.contractInfo.maxMintsPerTX} step={1} onChange={(value)=> {setMintAmount(value)}}
                        width="400px"
                        >
                        <SliderTrack bg={'red.300'}>
                            <Box color='black.800'/>
                            <SliderFilledTrack bg={'black.700'} />
                        </SliderTrack>
                        <SliderThumb backgroundColor="black" boxSize={4} />
                    </Slider>
                    <Box align="center" paddingLeft="15" color="black" onClick={(value)=>handleIncrement()} >
                        <Button
                            backgroundColor='black'
                            color='#fafafa'
                            aria-label={'Add '+dataEth.contractInfo.tokenSymbol}
                        >+</Button>
                    </Box>
                </Flex>

                <Button
                    backgroundColor="black"
                    borderRadius="1px"
                    boxShadow="0px 2px 2px 1px #0F0F0F"
                    color="white"
                    cursor="pointer"
                    fontFamily="inherit"
                    padding="15px"
                    marginTop="10px"
                    onClick={handleMintClick}
                >
                    {'Mint Now'}
                    
                </Button>
                <Text marginTop="10px" fontSize="12px" textShadow="0 2px #DADADE">{dataEth?.contractInfo?.mintPrice ? Number(dataEth.contractInfo.mintPrice * mintAmount).toFixed(4) : ""} Ξ</Text>
                <Text fontSize="12px" textShadow="0 2px #DADADE">{dataEth?.contractInfo?.totalSupply ? dataEth.contractInfo.totalSupply + " / " + dataEth.contractInfo.maxSupply : ""}</Text>

                <Text marginTop="20px" style={{ display: (!dataEth.contractInfo.walletFreeMintsEnabled && !dataEth.contractInfo.freeMintEnded) ? 'blocked' : 'none'}}>
                    Already claimed your freebies!
                </Text>

                <Text marginTop="20px" style={{ display: dataEth.contractInfo.freeMintEnded? 'blocked' : 'none'}}>
                    Already claimed all freebies!
                </Text>

        </>
        );

        }
    }

    return(
        <>
            {availableMint()}
        </>
    )

}

export default ProjectMint;