import { Box, Text, Flex } from "@chakra-ui/react"
import ProjectMint from './ProjectMint';
import env from '../env';

const ProjectStatus=({ dataEth, setDataEth, message, setMessage}) => {

    const notStartedPaused = () => {
        let htmlData="You miss it!";
        let secondaryData="";
        if (!dataEth.contractInfo.loadedContract) {
            htmlData ="Error Loading Contract: "+env.mintContract;
        } else if (dataEth.contractInfo.totalSupply===0) {
            htmlData ="Final RektPixels Revealed";
        } else if (parseInt(dataEth.contractInfo.totalSupply)<parseInt(dataEth.contractInfo.maxSupply)) {
            htmlData =" Stay tuned to mint your Rektpixel";
        } else {
            // Sold Out
            secondaryData=<a href={env.secondaryMarket} target='_blank' rel='noreferrer noopener'> 
                <Text marginTop='10px' fontSize='18px'>Check our Secondary Market</Text>
            </a>;
        }

        return (
            <Flex align="center" justify="center">
                <Box width="80%">
                    <Text marginTop="10px" fontSize="24px" textShadow="0 2px #090909">{htmlData}</Text>
                    {secondaryData}
                </Box>
            </Flex>
        )
    }

    const notConnected = () => {
        return (
                <>
                    <Text
                        fontSize="24px"
                        textShadow="0 2px 2px #010101"
                        marginBottom="2"
                    >
                        You must be connected to Mint.
                    </Text>
                </>
        )
    }

    const availableMint = () => {
        return (
            <>
                <ProjectMint dataEth={dataEth} setDataEth={setDataEth} message={message} setMessage={setMessage} />
            </>
        )
    }

    const notNetwork = () => {
        return (
            <>
            <Text
                fontSize="24px"
                textShadow="0 2px 2px #010101"
                marginBottom="2"
            >
                    Check Network in Wallet
            </Text>
            </>);
    }

    const renderStatus = () => {
        let htmlData="";
        if (!dataEth?.isConnected) {
            htmlData=notConnected();
        } else if (dataEth?.networkConnected!==undefined && dataEth?.networkConnected!==env.networks[env.networkRequired].chainId) {
            htmlData=notNetwork();
        } else if (!dataEth?.contractInfo?.enabledMint) {
            htmlData=notStartedPaused();
        } else if (dataEth?.contractInfo?.enabledMint) {
            if (dataEth?.contractInfo?.totalSupply===dataEth.contractInfo.maxSupply) {
                htmlData=notStartedPaused()
            } else {
                htmlData=availableMint()
            }
        }
        
        return(
            <Flex justify="center" align="center" height="100%" paddingBottom="20px" paddingTop="0px">
                <Box width="650px">
                    {htmlData}
                </Box>
            </Flex>
        );

    }


    return(
        <>
            {renderStatus()}
        </>
    )
}

export default ProjectStatus;